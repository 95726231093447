<template>
  <en-dialog :model-value="modelValue" width="80%" title="移库出库列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-download :ajax="{ action: 'GET /enocloud/stock/transfer/in/export', params: (params) => (params.payload = table.$ajaxParams) }">
        导出
      </button-download>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STTFISTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="STFOFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
      show-summary
      :summary="table.summary.data"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/out/query',
            summary: 'GET /enocloud/stock/transfer/out/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        config: {
          OPERATION: { visible: false },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          PREPARED_DATETIME: {
            header: { filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } } }
          },
          FROM_BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'fromBranchId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          FROM_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'fromWarehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TO_BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'toBranchId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TO_WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'toWarehouseId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          COMMENT: {
          header: {
            filter: {
              type: 'text',
              field: 'comment'
            }
          }
        }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
